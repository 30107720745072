/* eslint-disable no-console */
/* eslint-disable no-else-return */

/**
@fileoverviewThis file contains actions related to user authentication and marketing updates on the qBraid platform.
It includes actions for handling OAuth and Cognito user login, marketing updates, refreshing API tokens, sending error emails, checking for existing users, verifying OTPs, verifying user IPs, and verifying partner names.
@description The User Actions include:
- OAuth and Cognito user login with polling and retry logic
- Handling marketing updates by adding tags to Mailchimp on the API
- Refreshing API tokens
- Sending error emails
- Checking for existing users
- Verifying OTPs
- Verifying user IPs and sending an OTP to the user email if the IP is not present in the database
- Verifying partner names
@todo
Improve error handling and user feedback for all actions
Refactor the login process to improve security and user experience
Refactor the marketing update process to improve robustness and efficiency
Refactor the API token refresh process to improve security and efficiency
Copyright (C) 2024 qBraid Development Team 
*/

import {
	confirmResetPassword,
	fetchAuthSession,
	getCurrentUser,
	resetPassword,
	signIn,
	signInWithRedirect,
	signOut,
	signUp,
	updatePassword
} from 'aws-amplify/auth';
import Cookies from 'universal-cookie';
import Store from '../Store';
import {
	ACCOUNT_URL,
	LAB_URL,
	axiosClient,
	email
} from '../../utils/axiosClient';
import { sendRGAEvent } from '../../utils/googleAnalytics';
import { getFilePathFromHashedPath } from '../../utils/utilityFunction';
import { currentDomain } from '../../utils/domainBasedInfo/currentDomain';
import {
	MARKETING_UPDATE_FAIL,
	MARKETING_UPDATE_LOADING,
	MARKETING_UPDATE_SUCCESS,
	REFRESH_API_FAILURE,
	REFRESH_API_LOADING,
	REFRESH_API_SUCCESS,
	USER_COGNITO_REMOVE,
	USER_COGNITO_STORE,
	USER_DATA_FAIL,
	USER_DATA_LOADING,
	USER_DATA_SUCCESS,
	USER_EDIT_PROFILE_FAIL,
	USER_EDIT_PROFILE_LOADING,
	USER_EDIT_PROFILE_SUCCESS,
	USER_FORGOT_PASSWORD_FAIL,
	USER_FORGOT_PASSWORD_LOADING,
	USER_FORGOT_PASSWORD_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGIN_LOADING,
	USER_LOGIN_SUCCESS,
	USER_LOGOUT_FAIL,
	USER_LOGOUT_LOADING,
	USER_LOGOUT_SUCCESS,
	USER_SIGNUP_FAIL,
	USER_SIGNUP_LOADING,
	USER_SIGNUP_SUCCESS,
	USER_SUBMIT_NEW_PASSWORD_FAIL,
	USER_SUBMIT_NEW_PASSWORD_LOADING,
	USER_SUBMIT_NEW_PASSWORD_SUCCESS
} from '../Types/userTypes';
import { apiDown } from './loadingErrMsgAction';

const isLocalHost =
	(window.location.href.match('localhost:300') || []).length === 1;
const domain = !isLocalHost ? currentDomain.domain : 'localhost';

function GenerateAuthCookies(userToken) {
	if (userToken !== null) {
		const cookies = new Cookies();
		const clientId = userToken?.tokens?.accessToken?.payload?.client_id;
		let userSub = userToken?.userSub;
		if (localStorage.getItem('isGoogle')) {
			userSub = userToken?.tokens?.accessToken?.payload?.username;
		}
		const refreshToken = localStorage.getItem(
			`CognitoIdentityServiceProvider.${clientId}.${userSub}.refreshToken`
		);
		const loginid = userToken?.tokens?.idToken?.payload?.email;
		const now = new Date();
		const oneWeek = now.setHours(now.getHours() + 168);
		// document.cookie = `EMAIL=${user.attributes.email};SameSite=Lax;Path=/;Expires=${oneWeek};Domain=${domain}`;
		// TODO: Update cookies to meet Ref 6265 compliance.
		cookies.set('EMAIL', loginid, {
			expire: oneWeek,
			sameSite: 'lax',
			path: '/',
			domain: domain
		});
		cookies.set('REFRESH', refreshToken, {
			expire: oneWeek,
			sameSite: 'lax',
			path: '/',
			domain: domain
		});
	}
}

function ClearAuthCookies() {
	const cookies = new Cookies();

	cookies.remove('GTAGID', {
		sameSite: 'lax',
		path: '/',
		domain: domain
	});
	cookies.remove('EMAIL', {
		sameSite: 'lax',
		path: '/',
		domain: domain
	});
	cookies.remove('ACCESS', {
		sameSite: 'lax',
		path: '/',
		domain: domain
	});
	cookies.remove('REFRESH', {
		sameSite: 'lax',
		path: '/',
		domain: domain
	});
	// Clear all cookies
	const allCookies = cookies.getAll();
	// eslint-disable-next-line no-restricted-syntax, guard-for-in
	for (const cookie in allCookies) {
		console.log('cookie', cookie);
		cookies.remove(cookie, {
			sameSite: 'lax',
			path: '/',
			domain: domain
		});
	}
	cookies.remove('logout', {
		sameSite: 'lax',
		path: '/',
		domain: domain
	});
}

async function StopLabServer(labUrl) {
	try {
		await axiosClient().post('/api/auth/logout', {
			email: email,
			labUrl: labUrl
		});
	} catch (err) {
		// eslint-disable-next-line no-console
		console.log('LAB was not opened!', err.message);
	}
}

export const UserSignUpFunction =
	(credentials, attributes) => async (dispatch) => {
		dispatch(UserSignUpLoading());
		try {
			const newEmail = credentials?.email?.toLowerCase();
			const user = await axiosClient().get(
				`/api/auth/check-for-user/${newEmail}`
			);
			if (!user.data.exists) {
				const response = await signUp({
					username: newEmail,
					password: credentials.password,
					options: {
						userAttributes: attributes
					}
					// optional,
				});
				dispatch(UserSignUpSuccess());
				return response;
			}
			// Duplicate user found in the database.
			return dispatch(UserSignUpFail(user.data.message));
		} catch (err) {
			let { message } = err;
			if (err.message === 'Network Error') {
				message =
					'Sorry, there was an error with the sign up procedure. Please come back later and try again';
			}
			if (err?.response?.status === 500) {
				dispatch(apiDown('API is down !'));
			}
			return dispatch(UserSignUpFail(message));
		}
	};

export const UserSignUpLoading = () => ({
	type: USER_SIGNUP_LOADING
});
export const UserSignUpSuccess = (payload) => ({
	type: USER_SIGNUP_SUCCESS,
	payload
});
export const UserSignUpFail = (payload) => ({
	type: USER_SIGNUP_FAIL,
	payload
});

export const UserLogoutFunction =
	(redirect = true) =>
		async (dispatch) => {
			dispatch(UserLogoutLoading());
			try {
				localStorage.clear();
				ClearAuthCookies();
				StopLabServer(LAB_URL);
				dispatch(UserCognitoUserRemove());
				await signOut();
				const cookies = new Cookies();
				sendRGAEvent('Logout', 'logout');
				// Set a cookie to have lab logout
				const now = new Date();
				const oneWeek = now.setHours(now.getHours() + 168);
				cookies.set('logout', {
					expire: oneWeek,
					sameSite: 'lax',
					path: '/',
					domain: domain
				});
				localStorage.setItem('logout', true); // This is used to trigger the logout modal
				if (redirect) {
					window.location.href = `https://auth${currentDomain.domain}/logout?client_id=${currentDomain.userPoolClientId}&logout_uri=${ACCOUNT_URL}`;
				}
				return dispatch(UserLogoutSuccess());
			} catch (err) {
				return dispatch(UserLogoutFail());
			}
		};

export const UserLogoutLoading = () => ({
	type: USER_LOGOUT_LOADING
});
export const UserLogoutSuccess = (payload) => ({
	type: USER_LOGOUT_SUCCESS,
	payload
});
export const UserLogoutFail = () => ({
	type: USER_LOGOUT_FAIL
});

export const UserLoginFunction = (credentials) => async (dispatch) => {
	dispatch(UserLoginLoading());
	let loginResponse;
	try {
		// logout any existing user.
		await signOut();
		if (credentials === 'Google') {
			localStorage.setItem('isGoogle', true);
			localStorage.setItem('reload', true);
			signInWithRedirect({
				provider: 'Google'
			})
				.then((res) => {
					console.log('res', res);
				})
				.error((err) => {
					console.log('err', err);
				});
			return;
		}
		loginResponse = await signIn({
			username: credentials.email.toLowerCase(),
			password: credentials.password
		});
		if (loginResponse?.nextStep?.signInStep === 'DONE') {
			await getCurrentUser();
			const userToken = await fetchAuthSession();
			if (window.location.pathname.includes('/login/partner/')) {
				await dispatch(UserCognitoUserStore(userToken));
			} else {
				GenerateAuthCookies(userToken);
			}
			localStorage.setItem('reload', true);
		}

		// window.location.reload();
		dispatch(UserLoginSuccess(loginResponse));
		return loginResponse;
	} catch (err) {
		return dispatch(UserLoginFail(err.message));
	}
};

export const UserLoginLoading = () => ({
	type: USER_LOGIN_LOADING
});
export const UserLoginSuccess = (payload) => ({
	type: USER_LOGIN_SUCCESS,
	payload
});
export const UserLoginFail = (payload) => ({
	type: USER_LOGIN_FAIL,
	payload
});

export const UserCognitoUserStore = (payload) => ({
	type: USER_COGNITO_STORE,
	payload
});

export const UserCognitoUserRemove = () => ({
	type: USER_COGNITO_REMOVE
});

export const UserDataFunction = () => async (dispatch) => {
	dispatch(UserDataLoading());
	try {
		const result = await axiosClient().get('/api/get-user-info');
		const gtagId = result?.data?.gtagId;
		const cookies = new Cookies();
		const now = new Date();
		const oneWeek = now.setHours(now.getHours() + 168);
		const gtagCookie = cookies.get('GTAGID');
		cookies.set('GTAGID', gtagId, {
			expire: oneWeek,
			sameSite: 'lax',
			path: '/',
			domain: domain
		});
		if (!gtagCookie) {
			sendRGAEvent('Login', 'login');
		}
		return dispatch(UserDataSuccess(result.data));
	} catch (err) {
		return dispatch(UserDataFail());
	}
};

export const UserDataLoading = () => ({
	type: USER_DATA_LOADING
});
export const UserDataSuccess = (payload) => ({
	type: USER_DATA_SUCCESS,
	payload
});
export const UserDataFail = () => ({
	type: USER_DATA_FAIL
});

export const handleEditProfileFunction =
	(fieldsToAssign) => async (dispatch) => {
		dispatch(EditProfileLoading());
		try {
			const result = await axiosClient().put(
				'/api/edit-user-personal-info',
				fieldsToAssign
			);
			if (result.data.success) {
				dispatch(EditProfileSuccess(result.data));
				dispatch(UserDataFunction());
			}
			return result;
		} catch (err) {
			return dispatch(EditProfileFail());
		}
	};

export const EditProfileLoading = () => ({
	type: USER_EDIT_PROFILE_LOADING
});
export const EditProfileSuccess = (payload) => ({
	type: USER_EDIT_PROFILE_SUCCESS,
	payload
});
export const EditProfileFail = () => ({
	type: USER_EDIT_PROFILE_FAIL
});

///

export const UserForgotPasswordFunction = (username) => async (dispatch) => {
	dispatch(UserForgotPasswordLoading());

	try {
		const response = await resetPassword({ username });
		dispatch(UserForgotPasswordSuccess());
		return response;
	} catch (err) {
		dispatch(UserForgotPasswordFail(err.message));
		return err.message;
	}
};

export const UserForgotPasswordLoading = () => ({
	type: USER_FORGOT_PASSWORD_LOADING
});
export const UserForgotPasswordSuccess = (payload) => ({
	type: USER_FORGOT_PASSWORD_SUCCESS,
	payload
});
export const UserForgotPasswordFail = (payload) => ({
	type: USER_FORGOT_PASSWORD_FAIL,
	payload
});

export const UserSubmitNewPasswordFunction =
	(username, code, newPassword) => async (dispatch) => {
		dispatch(UserSubmitNewPasswordLoading());

		try {
			await confirmResetPassword({
				username: username,
				newPassword: newPassword,
				confirmationCode: code
			});
			return 'Success';
		} catch (err) {
			dispatch(UserSubmitNewPasswordFail(err.message));
			return err.message;
		}
	};

export const UserSubmitNewPasswordLoading = () => ({
	type: USER_SUBMIT_NEW_PASSWORD_LOADING
});
export const UserSubmitNewPasswordSuccess = (payload) => ({
	type: USER_SUBMIT_NEW_PASSWORD_SUCCESS,
	payload
});
export const UserSubmitNewPasswordFail = (payload) => ({
	type: USER_SUBMIT_NEW_PASSWORD_FAIL,
	payload
});

export const UserUpdatePasswordFunction =
	(oldPassword, newPassword) => async (_dispatch) => {
		try {
			await updatePassword({ oldPassword, newPassword });
			return 'Success';
		} catch (err) {
			return err;
		}
	};

// eslint-disable-next-line unused-imports/no-unused-vars
export const validateUser = (userEmail) => async (dispatch) => {
	try {
		const session = await fetchAuthSession();
		if (
			session?.tokens?.signInDetails?.loginId?.toLowerCase() ===
			userEmail.toLowerCase()
		) {
			return true;
		}
		return false;
	} catch (error) {
		return false;
	}
};

export const UserOAuthFunction = () => async (dispatch) => {
	// TODO: Update polling script to use hub.listen from aws-amplify
	dispatch(UserLoginLoading());
	// if google login is set skip the cookie check, and start polling for user
	const isGoogleLogin = localStorage.getItem('isGoogle');
	if (isGoogleLogin) {
		let count = 0; // current number of polls
		const LIMIT = 5; // maximum number of polls allowed
		const DELAY = 100; // milisecond delay between polls
		let done = null; // whether install has reached final state
		let success = null; // whether install was successfull
		const authenticatedUser = async () => {
			try {
				// let user = await Auth.currentAuthenticatedUser();
				const user = await getCurrentUser();

				const session = await fetchAuthSession();

				if (!user) {
					done = false;
					success = false;
				} else {
					done = true;
					success = true;
				}
				if (done) {
					if (window.location.pathname.includes('/login/partner/')) {
						dispatch(UserCognitoUserStore(session));
					} else {
						GenerateAuthCookies(session);
					}

					dispatch(UserLoginSuccess(user));
					return session;
				}
				if (count + 1 > LIMIT - 1) {
					// We've reached the maximum number of polls, so we fail.
					dispatch(UserLoginFail('OAuth Login Failed'));
				} else {
					setTimeout(() => {
						authenticatedUser();
					}, DELAY);
				}
			} catch (error) {
				done = false;
				success = false;
				// try again to reauthenticate
				count += 1;
				if (count + 1 > LIMIT - 1) {
					dispatch(UserLoginFail('OAuth Login Failed'));
				} else {
					setTimeout(() => {
						authenticatedUser(count);
					}, DELAY);
				}
			}

			return success;
		};
		// start polling
		const user = await authenticatedUser();
		if (!window.location.pathname.includes('/login/partner/')) {
			localStorage.removeItem('isGoogle');
		}
		return user;
	} else {
		// check for existing cookie and email
		// if it exist poll the amplify to get active session
		const cookies = new Cookies();
		const existingEmail = cookies.get('EMAIL');
		const existingRefreshToken = cookies.get('REFRESH');
		if (existingEmail && existingRefreshToken) {
			let count = 0; // current number of polls
			const LIMIT = 5; // maximum number of polls allowed
			const DELAY = 100; // milisecond delay between polls
			let done = null; // whether install has reached final state
			let success = null; // whether install was successfull
			const authenticatedUser = async () => {
				try {
					// let user = await Auth.currentAuthenticatedUser();
					const user = await getCurrentUser();
					const session = await fetchAuthSession();

					if (!user) {
						done = false;
						success = false;
					} else {
						done = true;
						success = true;
					}
					if (done) {
						if (window.location.pathname.includes('/login/partner/')) {
							dispatch(UserCognitoUserStore(session));
						} else {
							GenerateAuthCookies(session);
						}
						dispatch(UserLoginSuccess(user));
						return session;
					}
					if (count + 1 > LIMIT - 1) {
						// We've reached the maximum number of polls, so we fail.
						cookies.remove('EMAIL');
						cookies.remove('REFRESH');
						await signOut({ global: true });
						dispatch(UserLoginFail('OAuth Login Failed'));
					} else {
						setTimeout(() => {
							authenticatedUser();
						}, DELAY);
					}
				} catch (error) {
					done = false;
					success = false;
					// try again to reauthenticate
					count += 1;
					if (count + 1 > LIMIT - 1) {
						cookies.remove('EMAIL');
						cookies.remove('REFRESH');
						await signOut({ global: true });
						dispatch(UserLoginFail('OAuth Login Failed'));
					} else {
						setTimeout(() => {
							authenticatedUser(count);
						}, DELAY);
					}
				}

				return success;
			};
			// start polling
			const user = await authenticatedUser();
			return user;
		}
		// if cookie and token is not present signout user
		// ask to login again
		try {
			await signOut({ global: true });
			dispatch(UserLoginFail('OAuth Login Failed'));
			return false;
		} catch (err) {
			dispatch(UserLoginFail('OAuth Login Failed'));
			return false;
		}
	}
};

export const HandleMarketing = (marketing) => async (dispatch) => {
	dispatch(MarketingUpdateLoading());
	const tags = marketing
		? [{ name: 'marketing', status: 'active' }]
		: [{ name: 'marketing', status: 'inactive' }];
	try {
		// Adds tag to mailchimp on the API
		const result = await axiosClient().post('/api/marketing/tags', {
			tags: tags,
			subscribe: marketing
		});
		return dispatch(MarketingUpdateSuccess(result.data));
	} catch (err) {
		return dispatch(MarketingUpdateFail());
	}
};

export const MarketingUpdateLoading = () => ({
	type: MARKETING_UPDATE_LOADING
});

export const MarketingUpdateSuccess = (payload) => ({
	type: MARKETING_UPDATE_SUCCESS,
	payload
});

export const MarketingUpdateFail = () => ({
	type: MARKETING_UPDATE_FAIL
});

export const RefreshAPIToken = () => async (dispatch) => {
	dispatch(RefreshAPILoading());
	try {
		const result = await axiosClient().post('/api/refresh-user-token');
		return dispatch(RefreshAPISuccess(result.data));
	} catch (err) {
		return dispatch(RefreshAPIFailure());
	}
};

export const RefreshAPILoading = () => ({ type: REFRESH_API_LOADING });

export const RefreshAPISuccess = (payload) => ({
	type: REFRESH_API_SUCCESS,
	payload
});

export const RefreshAPIFailure = () => ({
	type: REFRESH_API_FAILURE
});

export const sendErrorEmail = (payload) => async () => {
	try {
		const response = await axiosClient().post('api/user/send-error-email', {
			message: payload
		});
		if (response.status === 200) {
			// eslint-disable-next-line no-console
			console.log('✔ Error has been reported to qBraid!');
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.log('✖ Unable to send error report!');
	}
};

export const checkforUser = async (userEmail) => {
	try {
		const response = await axiosClient().get(
			`/api/auth/check-for-user/${userEmail}`
		);
		return response;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.log('✖ Something went wrong!', err);
	}
};

export const verifyOTPAction =
	({
		otp,
		partnerName,
		_email,
		isKeepSignedInFlagChecked,
		hashedPath,
		userToken
	}) =>
		async () => {
			try {
				let filePath = null;
				if (hashedPath) {
					filePath = await getFilePathFromHashedPath(hashedPath);
				}
				const response = await axiosClient().post(
					`/api/auth/login/verify-otp/partner/${partnerName}`,
					{
						otp: otp,
						email: _email,
						isKeepSignedInFlagChecked: isKeepSignedInFlagChecked,
						filePath: filePath
					}
				);
				if (response.status === 201 && userToken) {
				// return response;
					GenerateAuthCookies(userToken);
				}
				return response;
			} catch (err) {
			// eslint-disable-next-line no-console
				console.warn(err);
			}
		};

export const verifyUserIpAction =
	({
		partnerName, _email, hashedPath, userToken 
	}) =>
		async () => {
		// verifies the IP and if IP is not present in db, it sends an OTP to the user email
			try {
				let token = userToken;
				if (token === null) {
				// if the passed token is null, fetch the token from store
					const userObjectFromStore = Store.getState().userReducer;
					token = userObjectFromStore?.userToken;
				}
				let filePath = null;
				if (hashedPath) {
					filePath = await getFilePathFromHashedPath(hashedPath);
				}
				const response = await axiosClient().post(
					`/api/auth/login/verify-user-ip/partner/${partnerName}`,
					{ email: _email, filePath: filePath }
				);
				if (response.status === 201 && token) {
				// for 201, we get laburl as response and redirect the user
					GenerateAuthCookies(token);
				}
				return response; // we want to send for all the code 200, 201, 403, 500 or handle errors here
			} catch (err) {
				return err;
			}
		};

export const verifyPartnerName = async ({ partnerName }) => {
	try {
		const response = await axiosClient().get(
			`/api/auth/verify-partner/${partnerName}`
		);
		if (response) {
			return response?.data?.exists;
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.log('Something went wrong', err);
	}
};

export const updateCookieConsent = async (consent) => {
	try {
		const response = await axiosClient().put(
			'/api/user/update-cookie-consent',
			{ consent }
		);
		if (response) {
			if (response.status === 200) return "success";
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.log('Something went wrong', err);
	}
};
