export const MUIComponents = {
	MuiButton: {
		styleOverrides: {
			root: {
				textTransform: 'none'
			}
		},
		variants: [
			{
				props: { variant: 'slide' },
				style: {
					border: 'none',
					color: 'white',
					textTransform: 'none',
					borderRadius: '100vw',
					backgroundImage: 'linear-gradient(110deg,#000 50%,#4144bb 50%)',
					backgroundSize: '222%',
					backgroundPosition: 'right',
					transition:
						'background-position 200ms linear, letter-spacing 200ms linear, transform 150ms ease-in-out',
					':hover': {
						backgroundPosition: 'left'
					},
					':disabled': {
						backgroundImage: 'linear-gradient(90deg, lightgrey, lightgrey)'
					}
				}
			},
			{
				props: { variant: 'slide', color: 'success' },
				style: {
					backgroundImage: 'linear-gradient(110deg,#8AB73A 50%,#673ab7 50%)'
				}
			},
			{
				props: { variant: 'slide', color: 'error' },
				style: {
					border: '1px solid #d32f2f',
					color: '#d32f2f',
					backgroundImage:
						'linear-gradient(110deg, #e03333 50%,transparent 50%)',
					':hover': {
						color: 'white'
					}
				}
			},
			{
				props: { variant: 'slide', color: 'outlined-gray' },
				style: {
					border: '.98px solid #e4e4e4',
					color: '#2f3246',
					backgroundImage:
						'linear-gradient(110deg,  #9b12df 50%,transparent 50%)',
					':hover': {
						color: 'white'
					}
				}
			},
			{ props: { pilled: true }, style: { borderRadius: '100vw' } }
		]
	}
};
