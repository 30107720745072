import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Tracker from '@openreplay/tracker';
import { email } from '../../utils/axiosClient';

const path = window.location.href;
const devMode = path.includes('staging') || path.includes('localhost');

const tracker = new Tracker({
	projectKey: process.env.REACT_APP_OPENREPLAY_KEY,
	ingestPoint: 'https://openreplay.qbraid.com/ingest'
	// respectDoNotTrack: true,
	// __DISABLE_SECURE_MODE: true // for local testing only
});

function Openreplay({ children }) {
	const { userDetails } = useSelector((state) => state.userReducer);
	const { metadata } = userDetails;
	useEffect(() => {
		if (!email || !metadata || devMode) return;

		if (metadata?.cookieConsentObject?.analytics_storage === 'granted') {
			if (tracker.isActive()) return;

			tracker.setUserID(email);
			tracker.start().catch((err) => {
				console.log('Openreplay session cannot be started!', err);
			});
		}
	}, [metadata]);

	return children;
}

export default Openreplay;
