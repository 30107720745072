import jwtDecode from 'jwt-decode';
import { ACCOUNT_URL } from './axiosClient';

export const calcDate = (date1, date2) => {
	/*
	 * calcDate() : Calculates the difference between two dates
	 * @date1 : "First Date in the format MM-DD-YYYY"
	 * @date2 : "Second Date in the format MM-DD-YYYY"
	 * return : Array
	 */

	// new date instance
	const dtDate1 = new Date(date1);
	const dtDate2 = new Date(date2);

	// Get the Timestamp
	const date1TimeStamp = dtDate1.getTime();
	const date2TimeStamp = dtDate2.getTime();

	let calc;

	// Check which timestamp is greater
	if (date1TimeStamp > date2TimeStamp) {
		calc = new Date(date1TimeStamp - date2TimeStamp);
	} else {
		calc = new Date(date2TimeStamp - date1TimeStamp);
	}
	// Retrieve the date, month and year
	const calcFormatTmp = `${calc.getDate()}-${
		calc.getMonth() + 1
	}-${calc.getFullYear()}`;
	// Convert to an array and store
	const calcFormat = calcFormatTmp.split('-');
	// Subtract each member of our array from the default date
	const daysPassed = Number(Math.abs(calcFormat[0]) - 1);
	const monthsPassed = Number(Math.abs(calcFormat[1]) - 1);
	const yearsPassed = Number(Math.abs(calcFormat[2]) - 1970);

	// Set up custom text
	const yrsTxt = ['year', 'years'];
	const mnthsTxt = ['month', 'months'];
	const daysTxt = ['day', 'days'];

	// Convert to days and sum together
	const totalDays = yearsPassed * 365 + monthsPassed * 30.417 + daysPassed;
	const totalSecs = totalDays * 24 * 60 * 60;
	const totalMins = totalDays * 24 * 60;
	const totalHours = totalDays * 24;
	const totalWeeks = totalDays >= 7 ? totalDays / 7 : 0;

	// display result with custom text
	const result = `${
		+yearsPassed === 1
			? `${yearsPassed} ${yrsTxt[0]} `
			: yearsPassed > 1
				? `${yearsPassed} ${yrsTxt[1]} `
				: ''
	} 
		${
	+monthsPassed === 1
		? `${monthsPassed} ${mnthsTxt[0]} `
		: monthsPassed > 1
			? `${monthsPassed} ${mnthsTxt[1]} `
			: ''
} 
		${
	+daysPassed === 1
		? `${daysPassed} ${daysTxt[0]}`
		: daysPassed > 1
			? `${daysPassed} ${daysTxt[1]}`
			: ''
}`;

	// return the result
	return {
		totalDays: Math.round(totalDays),
		totalWeeks: Math.round(totalWeeks),
		totalHours: Math.round(totalHours),
		total_minutes: Math.round(totalMins),
		total_seconds: Math.round(totalSecs),
		result: result.trim()
	};
};

export const getPartnerName = (pathname) => pathname.split('/')[4];

export const getAccountURL = (pathname) => {
	if (pathname.includes('xanadu')) {
		return `${ACCOUNT_URL}auth/login/partner/${getPartnerName(pathname)}`;
	}
	return `${ACCOUNT_URL}oauth`;
};

export const getFilePathFromHashedPath = async (hashedPath) => {
	try {
		const decodedObject = jwtDecode(hashedPath);
		return decodedObject ? decodedObject.filePath : null;
	} catch (error) {
		return null;
	}
};
