export const FETCH_USER_SUBSCRIPTION_LOADING = 'USER_SUBSCRIPTION_LOADING';
export const FETCH_USER_SUBSCRIPTION_SUCCESS = 'USER_SUBSCRIPTION_SUCCESS';
export const FETCH_USER_SUBSCRIPTION_FAIL = 'USER_SUBSCRIPTION_FAIL';
export const NO_USER_SUBSCRIPTION = 'NO_USER_SUBSCRIPTION';

export const FETCH_CARD_INFO_LOADING = 'CARD_INFO_LOADING';
export const FETCH_CARD_INFO_SUCCESS = 'CARD_INFO_SUCCESS';
export const FETCH_CARD_INFO_FAIL = 'CARD_INFO_FAIL';
export const ADD_NEW_CARD = 'ADD_NEW_CARD';

export const FETCH_USER_PREV_SUBS_SUCCESS = 'FETCH_USER_PREV_SUBS_SUCCESS';
export const FETCH_USER_PREV_SUBS_LOADING = 'FETCH_USER_PREV_SUBS_LOADING';
export const FETCH_USER_PREV_SUBS_FAILURE = 'FETCH_USER_PREV_SUBS_FAILURE';
export const NO_USER_PREV_SUBSCRIPTION = 'NO_USER_PREV_SUBSCRIPTION';
