/**
 * @fileoverviewUser Signup Modal
 * @description This file contains the user signup modal component
 * @copyright (C) 2024 qBraid Development Team
 */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import {
	Checkbox,
	Dialog,
	DialogContent,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	TextField,
	useMediaQuery,
	useTheme
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import googleimg from '../../../assets/images/google.png';
import ModalDesc from '../modal-desc';
import {
	UserSignUpFunction,
	UserLoginFunction
} from '../../../Redux/Action/userAction';
import { useQuery } from '../../../hooks/useQuery';
import { currentDomain } from '../../../utils/domainBasedInfo/currentDomain';
import './index.css';

const HASHEDPATH = 'hashed_path';

export default function SignUpModal({
	openSignUp,
	setOpenSignUp,
	handleLogin,
	setOpenLogin,
	setOpenSnack,
	setSnackMessage,
	setSnackColor,
	setLastEmail,
	partnerAuth,
	userEmail
}) {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const query = useQuery();
	const muiTheme = useTheme();
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.userReducer);
	const validationSchema = yup.object({
		firstName: yup
			.string('Enter your first name')
			.required('First Name is required'),

		lastName: yup.string('Enter your email').required('Last Name is required'),
		email: yup
			.string('Enter your email')
			.matches(
				/^[a-zA-Z0-9@._-]+$/,
				'An email cannot contain white spaces and special characters'
			)
			.email('Enter a valid email')
			.required('Email is required'),
		password: yup
			.string('Enter your password')
			.min(8, 'Must be 8 characters or more')
			.required('Password is required')
			.matches(/[a-z]+/, 'One lowercase character')
			.matches(/[A-Z]+/, 'One uppercase character')
			.matches(/[@$!%*#?&]+/, 'One special character')
			.matches(/\d+/, 'One number'),

		confirmPassword: yup
			.string('Enter your password')
			.required('Password is required')
			.oneOf([yup.ref('password')], 'Your passwords do not match.')
			.min(8, 'Must be 8 characters or more')
			.matches(/[a-z]+/, 'One lowercase character')
			.matches(/[A-Z]+/, 'One uppercase character')
			.matches(/[@$!%*#?&]+/, 'One special character')
			.matches(/\d+/, 'One number'),

		acceptTerms: yup
			.bool()
			.oneOf([true], 'Please accept terms & Conditions!')
			.required('Please accept Terms & Conditions!')
	});
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: userEmail || '',
			password: '',
			confirmPassword: '',
			acceptTerms: false,
			marketing: false
		},
		validationSchema,
		onSubmit: async (credentials) => {
			const attributes = {
				'custom:firstName': credentials.firstName,
				'custom:lastName': credentials.lastName,
				'custom:marketing': credentials.marketing ? 'true' : 'false' // must be string for Cognito
			};
			const res = await dispatch(UserSignUpFunction(credentials, attributes));
			if (res?.nextStep?.signUpStep === 'CONFIRM_SIGN_UP') {
				setLastEmail(res?.user?.username);
				setOpenLogin(true);
				setSnackColor(false);
				setOpenSnack(!loading);
				setOpenSignUp(false);
				setSnackMessage('Sign Up Successful Please Check your email inbox');
				formik.handleReset();
			} else {
				setSnackColor(true);
				setOpenSnack(!loading);
				setSnackMessage(res?.payload);
			}
		}
	});

	const showPasswordHandler = () => setShowPassword(!showPassword);
	const showConfirmPasswordHandler = () =>
		setShowConfirmPassword(!showConfirmPassword);

	const handleGoogleSignup = () => {
		if (partnerAuth) {
			const cookies = new Cookies();
			cookies.set(HASHEDPATH, query.get(HASHEDPATH));
		}
		dispatch(UserLoginFunction('Google'));
	};

	return (
		<Dialog
			open={openSignUp}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			maxWidth="fitContent"
			fullScreen={useMediaQuery(muiTheme.breakpoints.down('sm'))}
		>
			<DialogContent sx={{ p: 0, maxWidth: 800 }}>
				<Grid container>
					<Grid
						item
						container
						lg={6}
						md={0}
						sm={0}
						xs={0}
						sx={{
							display: {
								xs: 'none',
								sm: 'none',
								md: 'none',
								lg: 'flex'
							}
						}}
						justifyContent="center"
						className="imgGridSinUp"
					>
						<Grid item xs={12}>
							<ModalDesc
								title={`Join ${
									partnerAuth ? 'QHack' : currentDomain.title
								} for free`}
								desc={
									partnerAuth
										? 'QHack is a world-renowned annual quantum hackathon that takes your education to the next level'
										: currentDomain.loginModal.description
								}
							/>
						</Grid>
					</Grid>

					<Grid
						item
						container
						xs={12}
						sm={12}
						md={12}
						lg={6}
						className="form-Grid"
					>
						<form onSubmit={formik.handleSubmit} className="sub_form">
							<Grid item container xs={12} justifyContent="center">
								<Grid container item xs={12}>
									<Button
										variant="outlined"
										sx={{
											color: 'black',
											borderColor: '#95999D',
											fontSize: '14px',
											fontFamily: partnerAuth
												? 'Righteous !important'
												: 'Inter !important',
											display: 'flex',
											flexDirection: 'row',
											gap: '8px',
											alignItems: 'center',
											justifyContent: 'center'
										}}
										fullWidth
										className="google-button"
										onClick={handleGoogleSignup}
									>
										<img
											src={googleimg}
											alt="logo"
											width="28px"
											height="28px"
										/>
										Create Account with Google
									</Button>
								</Grid>
								<Grid container item xs={12} justifyContent="center">
									<Typography
										className="signup-sectext"
										sx={{
											fontFamily: partnerAuth ? 'Righteous !important' : 'Inter'
										}}
									>
										Or
									</Typography>
								</Grid>
								<Grid
									container
									item
									xs={12}
									sx={{
										marginBottom: '0.5rem',
										justifyContent: 'space-between'
									}}
								>
									<Grid container item xs={6} sm={6} md={6} lg={6} xl={6}>
										<TextField
											className="textMargin"
											label="First Name"
											name="firstName"
											value={formik.values.firstName}
											onChange={formik.handleChange}
											error={
												formik.touched.firstName &&
												Boolean(formik.errors.firstName)
											}
											helperText={
												formik.touched.firstName && formik.errors.firstName
											}
											InputLabelProps={{
												style: { color: '#414345', fontSize: '14px' }
											}}
											sx={{
												fontFamily: partnerAuth
													? 'Righteous !important'
													: 'Inter'
											}}
											cy-data="firstName"
										/>
									</Grid>
									<Grid container item xs={6} sm={6} md={6} lg={6} xl={6}>
										<TextField
											className="textMargin"
											label="Last Name"
											name="lastName"
											value={formik.values.lastName}
											onChange={formik.handleChange}
											error={
												formik.touched.lastName &&
												Boolean(formik.errors.lastName)
											}
											helperText={
												formik.touched.lastName && formik.errors.lastName
											}
											sx={{
												marginLeft: '0.4rem',
												fontFamily: partnerAuth
													? 'Righteous !important'
													: 'Inter'
											}}
											InputLabelProps={{
												style: { color: '#414345', fontSize: '14px' }
											}}
											cy-data="lastName"
										/>
									</Grid>
								</Grid>
								<Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
									<TextField
										className="textfieldMargin"
										label="Email Address"
										name="email"
										fullWidth
										value={formik.values.email}
										onChange={formik.handleChange}
										error={formik.touched.email && Boolean(formik.errors.email)}
										helperText={formik.touched.email && formik.errors.email}
										InputLabelProps={{
											style: { color: '#414345', fontSize: '14px' }
										}}
										sx={{
											fontFamily: partnerAuth ? 'Righteous !important' : 'Inter'
										}}
										cy-data="email"
									/>
								</Grid>
								<Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
									<TextField
										className="textfieldMargin"
										label="Create Password"
										type={showPassword ? 'text' : 'password'}
										name="password"
										fullWidth
										value={formik.values.password}
										onChange={formik.handleChange}
										error={
											formik.touched.password && Boolean(formik.errors.password)
										}
										helperText={
											formik.touched.password && formik.errors.password
										}
										InputLabelProps={{
											style: { color: '#414345', fontSize: '14px' }
										}}
										sx={{
											fontFamily: partnerAuth ? 'Righteous !important' : 'Inter'
										}}
										cy-data="password"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={showPasswordHandler}>
														{showPassword ? (
															<VisibilityOffIcon />
														) : (
															<VisibilityIcon />
														)}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
									<Typography
										className="pass-text"
										sx={{
											fontFamily: partnerAuth ? 'Righteous !important' : 'Inter'
										}}
									>
										Password must be 8 characters long with number and special
										character.
									</Typography>
								</Grid>

								<Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
									<TextField
										className="textfieldMargin"
										label="Confirm Password"
										type={showConfirmPassword ? 'text' : 'password'}
										name="confirmPassword"
										fullWidth
										value={formik.values.confirmPassword}
										onChange={formik.handleChange}
										error={
											formik.touched.confirmPassword &&
											Boolean(formik.errors.confirmPassword)
										}
										helperText={
											formik.touched.confirmPassword &&
											formik.errors.confirmPassword
										}
										InputLabelProps={{
											style: { color: '#414345', fontSize: '14px' }
										}}
										sx={{
											fontFamily: partnerAuth ? 'Righteous !important' : 'Inter'
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={showConfirmPasswordHandler}>
														{showConfirmPassword ? (
															<VisibilityOffIcon />
														) : (
															<VisibilityIcon />
														)}
													</IconButton>
												</InputAdornment>
											)
										}}
										cy-data="confirmPassword"
									/>
								</Grid>

								<Grid
									sx={{
										display: 'flex',
										alignItems: 'center',
										borderBottom: '1px solid #CCCCCC'
									}}
									container
									item
									xs={12}
								>
									<Grid item lg={2} md={2} sm={3} xs={3}>
										<Checkbox
											type="checkbox"
											color="primary"
											sx={(theme) => ({
												'& .MuiSvgIcon-root': { fontSize: 24 },
												paddingLeft: '0px!important',
												marginLeft: '-3px!important',
												marginTop: '0.4rem',
												color: theme.palette.grey[500],
												boxSizing: 'border-box',
												borderRadius: '5px'
											})}
											onChange={formik.handleChange}
											name="marketing"
											checked={formik.values.marketing}
											// value={formik.values.marketing}
										/>
									</Grid>

									<Grid item lg={10} md={10} sm={9} xs={9}>
										<Typography
											className="access-text"
											sx={{
												fontFamily: partnerAuth
													? 'Righteous !important'
													: 'Inter'
											}}
										>
											Subscribe to qBraid marketing to receive updates on
											events, releases, and more.
										</Typography>
									</Grid>
								</Grid>
								<Grid
									sx={{
										display: 'flex'
									}}
									container
									item
									xs={12}
								>
									<Grid
										item
										lg={2}
										md={2}
										sm={3}
										xs={3}
										sx={{ marginTop: '0.5rem' }}
									>
										<Checkbox
											name="acceptTerms"
											checked={formik.values.acceptTerms}
											// value={formik.values.acceptTerms}
											onChange={formik.handleChange}
											// error={
											// 	formik.touched.acceptTerms &&
											// 	Boolean(formik.errors.acceptTerms)
											// }
											error={formik.errors.acceptTerms}
											// helperText={
											// 	formik.touched.acceptTerms && formik.errors.acceptTerms
											// }
											color="primary"
											sx={(theme) => ({
												'& .MuiSvgIcon-root': { fontSize: 24 },
												paddingLeft: '0px!important',
												marginLeft: '-3px!important',
												marginTop: '0.4rem',
												color: theme.palette.grey[500],
												boxSizing: 'border-box',
												borderRadius: '5px'
											})}
											cy-data="acceptTerms"
										/>
									</Grid>
									<Grid item lg={10} md={10} sm={9} xs={9}>
										<Typography
											className="access-text"
											sx={{
												fontFamily: partnerAuth
													? 'Righteous !important'
													: 'Inter'
											}}
										>
											I have read and agree to {currentDomain.title}’s
											<Link
												color="#2E3338"
												className="linktext"
												aria-label="Terms & Conditions opens in new tab"
												target="_blank"
												href="/terms-and-conditions"
											>
												Terms & Conditions,
											</Link>
											<Link
												color="#2E3338"
												className="linktext"
												aria-label="Privacy Policy opens in new tab"
												target="_blank"
												href="/privacy-policy"
											>
												Privacy Policy
											</Link>
											and
											<Link
												color="#2E3338"
												aria-label="Cookie Policy opens in new tab"
												target="_blank"
												href="/cookie-policy"
												className="linktext"
											>
												Cookie Policy.
											</Link>
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Typography
										className="error-text"
										sx={{
											fontFamily: partnerAuth ? 'Righteous !important' : 'Inter'
										}}
									>
										{formik.touched.acceptTerms && formik.errors.acceptTerms}
									</Typography>
								</Grid>
								<Grid item container xs={12}>
									<Grid item lg={12} md={12} sm={12} xs={12} textAlign="center">
										<Typography
											className="check-mail-text"
											textAlign="center"
											sx={{
												fontFamily: partnerAuth
													? 'Righteous !important'
													: 'Inter'
											}}
										>
											Check your email to verify your account
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid container item xs={12}>
								<Button
									variant="contained"
									className={
										partnerAuth ? 'xanadu-signup-button' : 'signup_button'
									}
									sx={{
										fontFamily: partnerAuth && 'Righteous !important',
										height: 60
									}}
									type="submit"
									value="submit"
									cy-data="submit"
									fullWidth
								>
									{loading ? (
										<CircularProgress size={20} style={{ color: '#fff' }} />
									) : (
										'Create Account'
									)}{' '}
								</Button>
							</Grid>
							<Grid container item xs={12} justifyContent="center">
								<Typography
									sx={{
										color: '#2E3338',
										textAlign: 'center',
										fontFamily: partnerAuth ? 'Righteous !important' : 'Inter'
									}}
								>
									Already Signed Up?
									<Button
										sx={{
											color: '#2E3338',
											fontSize: '12px',
											'&:hover': {
												backgroundColor: 'rgba(133, 19, 247, 0.10)',
												boxShadow: ' 0px 18px 35px rgba(152, 64, 210, 0.15)'
											},
											fontFamily: partnerAuth ? 'Righteous !important' : 'Inter'
										}}
										onClick={handleLogin}
										className="link-button"
									>
										Log In
									</Button>
								</Typography>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
