export const lightThemePalette = {
	mode: 'light',
	primary: {
		main: '#4144bb'
	},
	secondary: {
		main: '#46096f'
	},
	background: {
		alternate: '#faf7fd'
	}
};

export const darkThemePalette = {
	mode: 'dark',
	primary: {
		main: '#4144bb'
	},
	secondary: {
		main: '#46096f'
	},
	background: {
		alternate: '#1e1e1e'
	}
};
