export const lightThemePalette = {
	mode: 'light',
	primary: {
		main: '#9b12df'
	},
	secondary: {
		main: '#560AA3'
	},
	background: {
		alternate: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)'
	}
};

export const darkThemePalette = {
	mode: 'dark',
	primary: {
		main: '#9b12df'
	},
	secondary: {
		main: '#560AA3'
	},
	background: {
		alternate: '#1e1e1e'
	}
};
