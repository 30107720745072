import {
	FETCH_ORGLIST_FAILED,
	FETCH_ORGLIST_LOADING,
	FETCH_ORGLIST_SUCCESS,
	ORGLIST_LOADING_STOP
} from '../Types/organizationTypes';

// Subs are subscriptions
const initialState = {
	orgList: [],
	isLoading: false,
	success: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_ORGLIST_LOADING: {
			return {
				...state,
				isLoading: true
			};
		}
		case FETCH_ORGLIST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				success: true,
				orgList: action.payload
			};
		}
		case FETCH_ORGLIST_FAILED: {
			return {
				...state,
				isLoading: false,
				success: false,
				orgList: []
			};
		}
		case ORGLIST_LOADING_STOP: {
			return {
				...state,
				isLoading: false
			};
		}
		default:
			return {
				...state
			};
	}
}
